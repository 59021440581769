.container {
  text-align: center;
}
.title {
  margin-top: 50px;
  margin-bottom: 50px;
}
.list {
  text-align: left;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
}